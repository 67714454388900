<template>
  <div class="picker">
    <div class="picker__input">
      <el-date-picker
        :disabled="props.loading"
        v-model="internalDate"
        type="daterange"
        start-placeholder="Период с"
        end-placeholder="Период по"
      />
    </div>

    <div class="button">
      <div class="button-icon">
        <el-tooltip
          :disabled="internalDate"
          content="Все поля обязательны"
          placement="top"
        >
          <el-button
            :loading="props.loading"
            :disabled="!internalDate"
            type="primary"
            :icon="ArrowRight"
            circle
            @click="handleChangeDate"
          />
        </el-tooltip>
      </div>

      <div class="button-primary">
        <el-tooltip
          :disabled="internalDate"
          content="Все поля обязательны"
          placement="top"
        >
          <el-button
            :loading="props.loading"
            :disabled="!internalDate"
            type="primary"
            @click="handleChangeDate"
          >
            Применить
          </el-button>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ArrowRight } from '@element-plus/icons-vue'
import { onMounted, ref, watch } from 'vue'
import {
  ElDatePicker,
  ElButton,
  ElSelect,
  ElOption,
  ElTooltip,
} from 'element-plus'
import { defineProps, defineEmits } from 'vue'
import dayjs from 'dayjs'
import { getLocations } from '@/services'
import { getCameras } from '@/services'

const props = defineProps({
  date: Array,
  data: Array,
  loading: Boolean,
})
const emits = defineEmits(['update:date'])

// const defaultStartDate = dayjs().subtract(7, 'day').format('YYYY-MM-DD')
// const defaultEndDate = dayjs().format('YYYY-MM-DD')

const internalDate = ref(props.date)

watch(
  () => props.date,
  newDate => {
    internalDate.value = newDate
  }
)

const handleChangeDate = () => {
  if (Array.isArray(internalDate.value) && internalDate.value.length === 2) {
    const formattedDate = [
      dayjs(internalDate.value[0]).format('YYYY-MM-DD'),
      dayjs(internalDate.value[1]).format('YYYY-MM-DD'),
    ]
    emits('update', { date: formattedDate })
  }
}
</script>

<style lang="scss">
.picker {
  display: flex;
  align-items: center;
  gap: 10px;

  &__input {
    display: flex;
    width: 300px;
  }

  &__select {
    display: flex;
  }
}

.button-icon {
  display: block;
}

.button-primary {
  display: none;
}

.el-input__wrapper {
  height: 32px;
}

@media (max-width: 520px) {
  .picker {
    flex-direction: column;

    &__input {
      width: 100%;
    }
  }

  .select {
    width: 100%;

    .el-input-wrapper {
      width: 100% !important;
    }
  }

  .button {
    width: 100%;

    &-icon {
      display: none;
    }

    &-primary {
      display: block;
    }

    button {
      width: 100%;
    }
  }

  .dashboard__blocks-left,
  .dashboard__blocks-right {
    width: 100%;
  }
}
</style>
