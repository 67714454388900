import { api } from '@/api'

export const getCheckins = async () => {
  const {
    data: { data },
    status,
  } = await api.get(`api/checkins`)

  return { data, status }
}

export const fetchDataCheckins = async (id: string) => {
  const {
    data: { data },
    status,
  } = await api.get(`api/checkins/${id}`)

  return { data, status }
}

export const updateCheckins = async (id: string, body: unknown) => {
  const {
    data: { data },
    status,
  } = await api.put(`api/checkins/${id}`, body)

  return { data, status }
}

export const createCheckins = async (id: string, body: unknown) => {
  const {
    data: { data },
    status,
  } = await api.post(`api/checkins`, body)

  return { data, status }
}
