import { api } from '@/api'

export const getAlbums = async params => {
  const {
    data: { data },
    status,
  } = await api.get(`api/chk-albums?filter=${params.filter}`)

  return { data, status }
}

export const getPhotosByAlbumId = async id => {
  const {
    data: { data },
    status,
  } = await api.get(`api/chk-albums/${id}`)

  return { data, status }
}

export const updatePhotosAlbum = async (id, body) => {
  const {
    data: { data },
    status,
  } = await api.put(`api/chk-album-photos/${id}`, body)

  return { data, status }
}
