import { api } from '@/api'

export const importFileClientWorkorders = async (file: File) => {
  const { data, status } = await api.post('api/client-workorders/import', {
    file,
    isJson: false,
  })

  return { data, status }
}
